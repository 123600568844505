:root {
  --header-height: 64px;
  --footer-height: 32px;
  --content-spacing: 32px;
  --content-spacing-narrow: 16px;
  --gentian-primary: #003d7a;
  --gentian-secondary: #0a70c4;
  --gentian-tertiary: #b9d38d;
  --gentian-light-gray: #f2f2f2;
  --gentian-gray: #808080;
  --gentian-dark-gray: #363636;
  --gentian-white: #ffffff;
  --gentian-black: #000000;
  /* MUI button disabled styles without opacity */
  --gentian-button-color-disabled: #bdbdbd;
  --gentian-button-bg-disabled: #e0e0e0;
}
