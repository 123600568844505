.pending-projects-table .MuiDataGrid-columnHeaderTitle {
  font-weight: bold;
  height: 56px;
  text-wrap: wrap;
  text-overflow: ellipsis;
  line-height: 1.43;
  align-content: center;
  justify-content: center;
}

.pending-projects-table .MuiDataGrid-cellContent {
  display: -webkit-box;
  overflow: hidden;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 3;
  line-clamp: 3;
  text-overflow: ellipsis;
  white-space: normal;
  height: fit-content;
  text-wrap: wrap;
  font-size: 12px;
}
